require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/1b07f526ab2675c1f595f218cd9d6b6f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1b07f526ab2675c1f595f218cd9d6b6f'] = async function fn_1b07f526ab2675c1f595f218cd9d6b6f(pageContent, dataSource) {
var result;


  result = (await Backendless.Data.of('admin_Webpages').save( ({ 'BlockContent': pageContent,'objectId': dataSource }) ));

return result
}

})
define('./functions/2a484e2882037fcf0007ebb10bfcbf2c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'] = async function fn_2a484e2882037fcf0007ebb10bfcbf2c(pageData, sectionName) {
var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



return (getObjectProperty(((await asyncListFilter((getObjectProperty((getObjectProperty(pageData, 'webpageCache')), 'webpagesections')), async (item) => {
   if ((getObjectProperty(item, 'sectionname')) == sectionName) {
    decision = true;
  } else {
    decision = false;
  }


 return decision;
}))[0]), 'HTMLContent'))
}

})
define('./functions/3319f6233504e8dfd1ccead7b42a6e98/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3319f6233504e8dfd1ccead7b42a6e98'] = function fn_3319f6233504e8dfd1ccead7b42a6e98() {
return 
}

})
define('./functions/3c63a6490c9c483f2ce74cbe8fb69592/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3c63a6490c9c483f2ce74cbe8fb69592'] = async function fn_3c63a6490c9c483f2ce74cbe8fb69592(HTMLContent) {
var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ((await asyncListFilter((getObjectProperty((getObjectProperty(HTMLContent, 'webpageData')), 'webpagesections')), async (item) => {
     if ((getObjectProperty(item, 'sectionname')) == sectionName) {
      decision = true;
    } else {
      decision = false;
    }


   return decision;
  }))[0])['HTMLContent'] = HTMLContent;

return 
}

})
define('./functions/476e0b7c9ee7ae9ea5da774edbe841c1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1'] = async function fn_476e0b7c9ee7ae9ea5da774edbe841c1() {
  if ((await Backendless.UserService.getUserRoles()).indexOf('ACCO Website Editor') + 1 > 0) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/7c99d93c763398d595b7ff4a51c14f57/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57'] = async function fn_7c99d93c763398d595b7ff4a51c14f57() {
var return2;


  return2 = (await (async function() {
  	return window.location.href
  })());

return return2
}

})
define('./functions/8dc91128a0037b9d1967b6ecd646bbb8/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8dc91128a0037b9d1967b6ecd646bbb8'] = async function fn_8dc91128a0037b9d1967b6ecd646bbb8(pageData, sectionName) {
var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



return (getObjectProperty(((await asyncListFilter((getObjectProperty((getObjectProperty(pageData, 'webpageData')), 'webpagesections')), async (item) => {
   if ((getObjectProperty(item, 'sectionname')) == sectionName) {
    decision = true;
  } else {
    decision = false;
  }


 return decision;
}))[0]), 'HTMLContent'))
}

})
define('./functions/90ffad4968bc0d1df319c785e7316443/code.js', () => { 

BackendlessUI.Functions.Custom['fn_90ffad4968bc0d1df319c785e7316443'] = function fn_90ffad4968bc0d1df319c785e7316443(ID) {
  console.log(String('For Popup: ') + String(ID));

return ID
}

})
define('./functions/d58e64163f69377f57fedfdaa18831c1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d58e64163f69377f57fedfdaa18831c1'] = async function fn_d58e64163f69377f57fedfdaa18831c1(pageData, sectionName) {
var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



return (getObjectProperty(((await asyncListFilter((getObjectProperty((getObjectProperty(pageData, 'webpageData')), 'webpagesections')), async (item) => {
   if ((getObjectProperty(item, 'id')) == sectionName) {
    decision = true;
  } else {
    decision = false;
  }


 return decision;
}))[0]), 'HTMLContent'))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/NOT_AUTHORIZED/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('mailto:mike@hyperpixels.net', true, undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/NOT_AUTHORIZED/components/e56c223c2ff4cb9b65c9147101973505/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/about/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var i, result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  ___arguments.context.pageData['numberOfYears'] = 59;
  ___arguments.context.pageData['numberOfEmployees'] = 118;
  ___arguments.context.pageData['numberOfClients'] = 300;
  ___arguments.context.pageData['numberOfProjects'] = 447;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	//content_css: 'writer',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));
  var i_end = (getObjectProperty(___arguments.context.pageData, 'numberOfProjects')) + 1;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (i < (getObjectProperty(___arguments.context.pageData, 'numberOfYears')) + 1) {
      await new Promise(r => setTimeout(r, (10 - 0) || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c274002b976dc20441bf0636ec941aee'))['content'] = i;
    }
    if (i < (getObjectProperty(___arguments.context.pageData, 'numberOfEmployees')) + 1) {
      await new Promise(r => setTimeout(r, (10 - 0) || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1c1345f33283d173d8e7f8c38e6deec4'))['content'] = i;
    }
    if (i < (getObjectProperty(___arguments.context.pageData, 'numberOfClients')) + 1) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b0a89b2f44f60165c9db18639245ca5a'))['content'] = i;
    }
    if (i < (getObjectProperty(___arguments.context.pageData, 'numberOfProjects')) + 1) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('07a4941ac7459fd4ecb3b29fd307dc16'))['content'] = i;
    }
  }

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/about/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/about/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/about/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/about/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].setAttribute('data-modified','true');
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'DFDDF878-ADDD-4433-9E1C-30BE78648EB8\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/about/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/about/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/about/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/about/components/6b65155455f2fb9a873bd5d20ab6cc09/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('tab1' == (getObjectProperty(___arguments.context.getPodDataModelByUid('6f6d1e1d04ad62b8b51fe8aab11534d9'), 'currentTabId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/about/components/1ff54d81f1bc4deabaa91b07ba0a0c95/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('tab2' == (getObjectProperty(___arguments.context.getPodDataModelByUid('6f6d1e1d04ad62b8b51fe8aab11534d9'), 'currentTabId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/about/components/ef526a194d17be81ba6cdf3d428fa20b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('tab3' == (getObjectProperty(___arguments.context.getPodDataModelByUid('6f6d1e1d04ad62b8b51fe8aab11534d9'), 'currentTabId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/about/components/aba5df0ac1c2804247112a9cde38551c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('tab4' == (getObjectProperty(___arguments.context.getPodDataModelByUid('6f6d1e1d04ad62b8b51fe8aab11534d9'), 'currentTabId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/about/components/b12ce348d9554b9be0542da96bd16391/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ('tab5' == (getObjectProperty(___arguments.context.getPodDataModelByUid('6f6d1e1d04ad62b8b51fe8aab11534d9'), 'currentTabId')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/about/components/b46ff9af9621bf28938449398ace4497/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'EBBDB1C0-02FB-48F7-AF7E-30F7BAB4CD54\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/116a1386810a7cd5b4aa586211dfb88e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'1E3412A7-A655-4F29-82B2-81139FA18B85\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/506a99ee23d8eb1f7c37e766586fc0fd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'0EAE0B3B-56E3-488D-B5DB-BF307B720A08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/a3d30e7ff676b9981be4664b1a5330ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'756BBA73-F666-4615-B028-4ABD49EBDF9B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/57a378e752caa571fdbf4038d569531f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'C7D7F8CB-F045-4EA4-AAA7-16CD6C362F26\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/about/components/c0a234050ce8318d9e5cfc09efaae0a6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'ACFE807E-163F-49C3-B774-4678BA5C32CF\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var buttonLogin, error, logging, password, stayLoggedIn, user, username, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    if ((await Backendless.UserService.getUserRoles()).indexOf('ACCO Website Editor') + 1 > 0) {
      ___arguments.context.appData['currentUser'] = user;
      ___arguments.context.appData['inAdminMode'] = true;
      localStorage.setItem('ACCOEditorCookie', JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'user-token'))));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
    } else {
      localStorage.removeItem('ACCOEditorCookie');
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('NOT_AUTHORIZED', undefined);
    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/employees/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/employees/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/employees/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/employees/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/employees/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/employees/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'5155423A-F695-4B2F-AADB-4D18281D0F89\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/employees/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/employees/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/employees/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/employees/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/employees_original/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    
  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/employees_original/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/employees_original/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees_original/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees_original/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/employees_original/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/employees_original/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('ACCOEditorCookie')));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/home/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/home/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/home/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'5155423A-F695-4B2F-AADB-4D18281D0F89\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/home/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/homeJSON/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/homeJSON/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, localWebpageData, pageID;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageID = '3B6B2404-C1AA-47E8-B2BB-AD3C6BB91242';
  ___arguments.context.pageData['pageID'] = pageID;
  localWebpageData = (getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',pageID,'\''].join(''))).setPageSize(1)))[0]), 'PageContent'));
  ___arguments.context.pageData['webpageData'] = localWebpageData;
  ___arguments.context.pageData['webpageCache'] = localWebpageData;
  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  ___arguments.context.pageData['pageLoaded'] = true;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_size_formats: "8px 10px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/homeJSON/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/homeJSON/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/homeJSON/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'landingBlock1'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'landingBlock2'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'landingBlock3'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/homeJSON/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var returnedData, sectionName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  sectionName = (await (async function(pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	// HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	// tinymce.activeEditor.setContent(HTMLSource);
  	// pageData.currentDataContent = HTMLSource;
  	return pageData.currentDataSource
  })(___arguments.context.pageData));
  console.log(String('The returned sectionId is: ') + String(sectionName));
  returnedData = (await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'currentDataSource'))));
  sectionName = (await (async function(pageData, dataToSet) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	// console.log ('The data source is: ' + pageData.currentDataSource);
  	// HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(dataToSet);
  	pageData.currentDataContent = dataToSet;
  })(___arguments.context.pageData, (decodeURIComponent(returnedData))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homeJSON/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'heroBlock2'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'landingBlock4'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'footerBlock1'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'footerBlock2'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/homeJSON/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homeJSON/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/homeJSON/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/homeJSON/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'heroBlock1'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/homeJSON/components/71dd7f53b1157583c11cd04885411f04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['inEditMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = (!(getObjectProperty(___arguments.context.pageData, 'taskBarTop')));
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	
  	
  	/*
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	*/
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});  
  	
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
      await ( async function (componentUid, content) {  ___arguments.context.getComponentByUid(componentUid).setHTML(content) })('bfb2a962391e895a7e4a504f3de89b48', (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	return loadFromBlock[0].innerHTML
  })(___arguments.context.pageData)));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var blockContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  blockContent = (await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getHTML() })('bfb2a962391e895a7e4a504f3de89b48'));
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent(blockContent)) }) );
  await (async function(pageData, richEditor) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = richEditor.innerHTML;
  	// ownerBlock[0].innerHTML; // get the innerHTML
  	// ownerBlock[0].innerHTML = decodeURI(sourceContent);
  	pageData.currentDataSource = ownerBlock[0].innerHTML;
  })(___arguments.context.pageData, ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bfb2a962391e895a7e4a504f3de89b48')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await ( async function (componentUid, content) {  ___arguments.context.getComponentByUid(componentUid).setHTML(content) })('bfb2a962391e895a7e4a504f3de89b48', (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','5155423A-F695-4B2F-AADB-4D18281D0F89','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/ed58afbbbd4bc0abe8216154b0cecf9e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home_builtIn_Quill/components/884d277472553f41b3f26a96d9835bc4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/industries/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));
  console.log('On Page Enter has been triggered!');

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/industries/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/industries/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/industries/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/industries/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/industries/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'5155423A-F695-4B2F-AADB-4D18281D0F89\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/industries/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/industries/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/industries/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/industries/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/lightbox/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    
  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/lightbox/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['font-weight'] = 'bold';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['font-weight'] = 'normal';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/lightbox/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['font-weight'] = 'bold';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['font-weight'] = 'normal';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/lightbox/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['font-weight'] = 'bold';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['font-weight'] = 'normal';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/newhome/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/newhome/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newhome/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newhome/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newhome/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newhome/components/1621ed6487d932e2d5c9c3cbb6345f25/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/newhome/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newhome/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/oldhomebackup/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/oldhomebackup/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/oldhomebackup/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/oldhomebackup/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/oldhomebackup/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/oldhomebackup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/projects-and-services/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/projects-and-services/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));
  console.log('On Page Enter has been triggered!');

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/projects-and-services/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/projects-and-services/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/projects-and-services/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/projects-and-services/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projects-and-services/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'5155423A-F695-4B2F-AADB-4D18281D0F89\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/projects-and-services/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/projects-and-services/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/projects-and-services/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/subcontractors/components/caee238c2f78b361364ba26ca6dd358d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('industries', undefined);

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('caee238c2f78b361364ba26ca6dd358d'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/subcontractors/components/55343ac7f355e5866b258f56b9374e51/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('55343ac7f355e5866b258f56b9374e51'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projects-and-services', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/e3dbcfc8260ddb18fbfa650584ebcb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e3dbcfc8260ddb18fbfa650584ebcb7b'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('about', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/9274dbcfe0ee894416a264e55332b426/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9274dbcfe0ee894416a264e55332b426'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('subcontractors', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/c7db3fda439eae33a98479749c1f6cdc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c7db3fda439eae33a98479749c1f6cdc'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('employees', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var WebsiteMode, AppBaseURL, AppURL;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(){ try { return JSON.parse(localStorage.getItem('ACCOEditorCookie')) } catch(e){ return null }})()));
  ___arguments.context.pageData['BETAItemsVisible'] = false;
  AppURL = (await BackendlessUI.Functions.Custom['fn_7c99d93c763398d595b7ff4a51c14f57']());
  AppBaseURL = AppURL.slice(0, 23);
  console.log(String('AppBaseURL = ') + String(AppBaseURL));
  WebsiteMode = (getObjectProperty(((await Backendless.Data.of('admin_Websites').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6D8FCB2C-2C2E-46E5-B5EC-5322F451E98C\'').setPageSize(1)))[0]), 'Current_Website_Mode'));
  if (AppBaseURL == 'https://app.webmojo.io/') {
    console.log('We\'re viewing the in-progress website');
    if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We want to: hideIfLiveMode items');
    } else if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    }
  } else if (AppBaseURL == 'https://beta.accofg.com') {
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
      console.log('We\'re viewing the Live, in-progress website!!!');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  } else if (AppBaseURL == 'https://www.accofg.com/') {
    console.log('We\'re viewing the temporary website if we\'re in BETA Mode, or the Live website if we\'re in Live Mode');
    if (WebsiteMode == 'BETA') {
      ___arguments.context.appData['Website_Mode'] = 'BETA';
      ___arguments.context.pageData['BETAItemsVisible'] = false;
      console.log('We\'re viewing the LIVE or BETA Website');
    } else if (WebsiteMode == 'Live') {
      ___arguments.context.appData['Website_Mode'] = 'Live';
      ___arguments.context.pageData['BETAItemsVisible'] = true;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var result2, result, currentDataSource;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = false;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }
  result = (await (async function(pageData) {
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute('data-source'));
  	  e.currentTarget.setAttribute('data-modified','true');
  	  console.log('This element has been marked as data-modified: ' + e.currentTarget.getAttribute('data-source'));
  	}
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('click', myFunction);
  	  
  	});
  })(___arguments.context.pageData));
  result2 = (await (async function(pageData, id) {
  	/*
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '#textEditor'});
  	// document.getElementById('textEditor').addEventListener("click", saveContent);
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent();
  	console.log(myContent);
  	pageData.myContent = myContent;
  	}
  	
  	// Plugins that we probably will need: mediaembed, export linkchecker?, powerpaste, advtable, editimage, autocorrect, typography, checklist
  	*/
  	
  	const editor = await BackendlessUI.requireModule('https://cdn.tiny.cloud/1/dwt7je7v9gjog7a8oc867xici2xmy80whuw0asnvstr20590/tinymce/6/tinymce.min.js')
  	tinymce.init({ selector: '.textEditor',
  	toolbar_sticky: true,
  	plugins: 'tinydrive code anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  	toolbar: 'undo redo | blocks fontfamily fontsize | fontsizeselect | forecolor backcolor | bold italic underline strikethrough blockquote | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code',
  	font_family_formats: 'Andale Mono="Andale Mono"; Arial=arial; Arial Black="arial black"; Book Antiqua = "book antiqua"; Comic Sans MS="Comic Sans MS"; Courier New=courier new,courier,monospace; Georgia=georgia; Helvetica=helvetica; Impact=impact; Tahoma=tahoma; Terminal=terminal; Times New Roman="times new roman"; Trebuchet MS = "trebuchet ms"; Sans Serif="sans serif"; Segoe UI=segoe ui; Verdana=verdana; Webdings=webdings; Wingdings=wingdings',
  	font_size_formats: "8px 10px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
  	toolbar_mode: 'wrap', // Modes: floating, sliding, scrolling, wrap
  	relative_urls : false,
  	remove_script_host : false,
  	document_base_url : 'https://www.accofg.com/',
  	tinydrive_dropbox_app_key: 'zf8ghuzc8e0s3ad'
  	}) //#${id}`}) `this was inside a template string`
  	document.getElementById('btnUpdateContent').addEventListener("click", saveContent)
  	
  	function saveContent() {
  	const myContent = tinymce.activeEditor.getContent()
  	pageData.myContent = myContent
  	}

  })(___arguments.context.pageData, (getObjectProperty((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71dd7f53b1157583c11cd04885411f04')), 'el')), 'id'))));
  console.log('On Page Enter has been triggered!');

  },
  /* handler:onEnter */
  /* handler:onResize */
  ['onResize'](___arguments) {
      if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 1252) {
    ___arguments.context.pageData['viewMode'] = 'Desktop';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 801 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 1252) {
    ___arguments.context.pageData['viewMode'] = 'Tablet';
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 600) {
    ___arguments.context.pageData['viewMode'] = 'Phone';
  }

  },
  /* handler:onResize */
  /* content */
}))

define('./pages/subcontractors/components/f6c3d992d48fe05b791cfba8a2c9ea19/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 1;
  	video.playbackRate = 1;
  	video.play();
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await (async function() {
  	const video = document.getElementsByTagName('video')[0]
  	video.muted = true;
  	// video.currentTime = 0;
  	video.playbackRate = 1;
  	video.pause();
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/subcontractors/components/27b9fd37c4233d555444978939bfe4e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    
  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/717adc521cbdf7ca7f3db4188da3a5c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'blockMouseOverEvent')) != true) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('717adc521cbdf7ca7f3db4188da3a5c7', true);
    ___arguments.context.pageData['blockMouseOverEvent'] = true;
  }

  },
  /* handler:onMouseOver */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    var result;


  result = (await (async function(pageData) {
  	// Create an array by ClassName 'content_editable' and invoke the function (editable) and create an event listener for each index in the array
  	// const runner = { currentDataSource: ""};
  	
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source has been set to: ' + pageData.currentDataSource);
  	HTMLSource = loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })(___arguments.context.pageData));

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/subcontractors/components/8cd91f8f8d38899143393ed8c8129c7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/4a16f104bfbce399523c5d8152efe8af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/e49eae259f43d5e4ed34fbed4c1690f2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'8D6FAC01-F4F5-466C-8D61-4C6395519121\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/d6338723fe754ea845c18574e1db8e3e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'B103EFB3-F65C-4F60-A3A7-0CBBB9F80C08\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/fa5129e49d61d9c6b57287189523e39e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'428FA853-8475-41D5-BEAE-0A758818D0BC\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/26ebd15ce2f2c1d6f9813d1a00a26054/bundle.js', [], () => ({
  /* content */
  /* handler:onAction */
  async ['onAction'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onAction */
  /* content */
}))

define('./pages/subcontractors/components/54dcab8d080f34d8921bdbb632146cd6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();//pageData.currentDataContent;
  	ownerBlock[0].innerHTML = `${sourceContent}`;
  })(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/c19816bbc89f510c6b0f61af2ce6e8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(data, pageData) {
  	var loadFromBlock, HTMLSource;
  	loadFromBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource);
  	console.log ('The data source is: ' + pageData.currentDataSource);
  	HTMLSource = data //loadFromBlock[0].innerHTML;
  	// loadFromBlock.currentTarget.getAttribute('data-source')
  	tinymce.activeEditor.setContent(HTMLSource);
  	pageData.currentDataContent = HTMLSource
  })((decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'\''].join(''))).setPageSize(1)))[0]), 'BlockContent')))), ___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/subcontractors/components/868de2a35dc382de3c1cd8fbad23a403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4645C476-DE2B-4007-9A54-03BBC0D8B746\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/4ab52bf14234a3b76ef19002bc343042/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'4087A0EA-27D1-45A5-97A2-9E787C8323E6\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/f84a59cfd95b86ecac0818e2f8369ed1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'5155423A-F695-4B2F-AADB-4D18281D0F89\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/7b1cb0a9932c78bfd8d5b79381e54204/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'A512CA81-3F67-48FE-9F52-1773D57EEC5B\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/c9b4f32bc24e77ea45d172ef6ed48f53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'','C1F6E9F5-7F62-4DEC-87C0-70A43E04649C','\''].join(''))).setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/subcontractors/components/8b7ea841affa30d6d4891c06e62e2d89/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogo-full', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogo-mini', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.event, 'ctrlKey')) == true && (getObjectProperty(___arguments.event, 'altKey')) == true) {
    ___arguments.context.pageData['hideButtonsMode'] = false;
    console.log(___arguments.event);
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', true);
    } else {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);
    }
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/subcontractors/components/e18a76e42143485dabe50caddb6fdd6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Desktop') {
    return ['companyLogoCell-right', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Tablet') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewMode')) == 'Phone') {
    return ['companyLogoCell-left', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/subcontractors/components/3ea61bdd3ad1e562a5185b8204761226/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['blockMouseOverEvent'] = false;
  await (async function(pageData) {
  	var ownerBlock;
  	var sourceContent;
  	ownerBlock = document.getElementsByClassName('DATA-'+ pageData.currentDataSource); // set reference to owner block control
  	sourceContent = tinymce.activeEditor.getContent();
  	pageData.currentDataContent = sourceContent; // added decodeURIComponent for troubleshooting

  })(___arguments.context.pageData);
  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onClick */
  /* handler:onVisibilityChange */
  async ['onVisibilityChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentDataSource')),'BlockContent': (encodeURIComponent((getObjectProperty(___arguments.context.pageData, 'currentDataContent')))) }) );

  },
  /* handler:onVisibilityChange */
  /* content */
}))

define('./pages/subcontractors/components/19f2f0dacd13571dc9b931385a463b62/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('19f2f0dacd13571dc9b931385a463b62', false);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/transactionsTest/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      ___arguments.context.pageData['products'] = (await Backendless.Data.of('Product').find(Backendless.DataQueryBuilder.create().setSortBy(['title']).setPageSize(100)));
  ___arguments.context.pageData['customers'] = (await Backendless.Data.of('Customer').find(Backendless.DataQueryBuilder.create().setSortBy(['name']).setPageSize(100)));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/transactionsTest/components/78b22c8adb4938a86e8ea55f42847ddb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    var subtotal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct'))) {
    return 0;
  }
  ___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198')['subtotal'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'quantity')) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct.price')));

  return (await (async function(value) {
	// Create our number formatter.
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',

	  // These options are needed to round to whole numbers if that's what you want.
	  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(value); /* $2,500.00 */
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'subtotal'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/transactionsTest/components/3dfc45070038bc4eb7a30f6b917c5624/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198')['selectedProduct'] = ((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'products')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments.value);
  }))[0]);

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'products'))

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/transactionsTest/components/1ad993a73c3e3dec25ea79683d1d7bef/bundle.js', [], () => ({
  /* content */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'customers'))

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/transactionsTest/components/a9530bd60873e8be2e89276a2cefb8f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    var total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct'))) {
    return 0;
  }
  total = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'subtotal')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'discount'))) * 1.0825;
  ___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198')['total'] = total;

  return (await (async function(value) {
	// Create our number formatter.
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',

	  // These options are needed to round to whole numbers if that's what you want.
	  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(value); /* $2,500.00 */
})(total))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/transactionsTest/components/fbdd45226784082ec1455bd97de146a9/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    var tax;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct'))) {
    return 0;
  }
  ___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198')['tax'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'subtotal')) * 0.0825);

  return (await (async function(value) {
	// Create our number formatter.
	const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',

	  // These options are needed to round to whole numbers if that's what you want.
	  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	return formatter.format(value); /* $2,500.00 */
})((getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'tax'))))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/transactionsTest/components/f60133aff1f2b36535ff2cdbb3ddbf44/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var transaction, newOrder;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function create_transaction() {
  transaction = (new Backendless.UnitOfWork('REPEATABLE_READ'));
  await ( async function() {
    const _uow = transaction;

    newOrder = (_uow.create('Order', ({ 'quantity': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'quantity')),'discount': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'discount')),'subtotal': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'subtotal')),'tax': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'tax')),'total': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'total')) })));
    _uow.setRelation('Order', newOrder, 'customer', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedCustomer'))]);
    _uow.setRelation('Order', newOrder, 'product', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct'))]);

  })();

  return transaction
}

/**
 * Describe this function...
 */
async function create_order_with_relations_without_transaction() {
  newOrder = (await Backendless.Data.of('Order').save( ({ 'quantity': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'quantity')),'discount': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'discount')),'subtotal': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'subtotal')),'tax': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'tax')),'total': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'total')) }) ));
  await Backendless.Data.of('Order').setRelation(newOrder, 'customer', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedCustomer'))]);
  await Backendless.Data.of('Order').setRelation(newOrder, 'product', [(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7c0b04e2cec89e870776fae7451dd198'), 'selectedProduct'))]);
}


  await (await create_transaction()).execute();
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('7c0b04e2cec89e870776fae7451dd198');
  ___arguments.context.pageData['showStatusBar'] = true;

  ;(function() {
    const callback = async () => {
        ___arguments.context.pageData['showStatusBar'] = false;

    };

    const timerId = '';
    const timerDelay = 2000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/90bac6e57c011e8316afe13568e29da5/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['text'] = 'info@accofg.com';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:info@accofg.com"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/d09f4d28c58125a4012e1443f1367509/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/300c980d76424a13b64788aad768898e/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('300c980d76424a13b64788aad768898e'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('300c980d76424a13b64788aad768898e'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.facebook.com/accofg', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/7d28f5675648b2b8723fa38590687b41/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7d28f5675648b2b8723fa38590687b41'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7d28f5675648b2b8723fa38590687b41'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.linkedin.com/company/anderson-construction-company-of-fort-gaines/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/6ffb65b9c5165a9712bbb3bcbf425dfe/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'headerBlock1'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/ef44e3b54c91f8b6d036fd417ebb4127/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ef44e3b54c91f8b6d036fd417ebb4127'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ef44e3b54c91f8b6d036fd417ebb4127'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_0158fcde2d6e09fbba18972e4d729e84/logic/051b08fd32bf3c1ce0df8c5bb31fa28f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (decodeURIComponent((await BackendlessUI.Functions.Custom['fn_2a484e2882037fcf0007ebb10bfcbf2c'](___arguments.context.pageData, 'headerBlock2'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/7dbef2fdfb5ce67e529d29023d486af5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarTop')) == true) {
    return ['taskBar-top', 'easeInAndOut'];
  } else {
    return ['taskBar-bottom', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/41a733c49e49c93f1865eeb65dd55c21/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['taskBarTop'] = false;
  ___arguments.context.pageData['taskBarBottom'] = (!(getObjectProperty(___arguments.context.pageData, 'taskBarTop')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarBottom')) != true) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/88719f2b76f7597ab457ed18de1c8fab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = (!(getObjectProperty(___arguments.context.pageData, 'taskBarTop')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarTop')) != true) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/1cf810357e1736ae1334fb2b92dd8cf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	}
  })();
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'Edit mode has been cancelled.', 'warning');
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/c2a713898dab8e5bcc146d2f63a1925b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/57fe146ddaa7470303bc7594cc321ac5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	  
  	  var isModified = el[i].getAttribute('data-modified');
  	  console.log(`The value of element ${i} is: ` + isModified);
  	
  	  if (isModified == 'true') {
  	    // console.log('This control is marked as true: ' + el[i])
  	    var dataId = el[i].getAttribute('data-source');
  	    console.log(`The value of the dataID is ${dataId}`);
  	    var dataSource = el[i].innerHTML;
  	    console.log(`The value of the dataSource is ${dataSource}`);
  	  // SAVE TO THE DB...
  	  await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': pageData.pageID ,'PageContent': (encodeURIComponent(dataSource)) }) );
  	   
  	  } else {
  	    // console.log('This control is NOT marked as true: ' + el[i])
  	    
  	  }
  	
  	}
  })(___arguments.context.pageData));
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, 'Reload', 'The webpage has been updated!','success');
  })();
  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	
  	}
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/54e49baf26769a9218ab1aa5e9973a07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	}
  })();
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'Edit mode has been cancelled.', 'warning');
  })();
  await Backendless.UserService.logout();
  await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/a063ee21d054b42660c911eda4ac320d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_10fcc25f35336d6b763587da9745cffa/logic/f63e128c4b16905e02113eba4f2451b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('pageData.webpageData = ');
  console.log(getObjectProperty(___arguments.context.pageData, 'webpageData'));
  console.log('pageData.webpageCache = ');
  console.log(getObjectProperty(___arguments.context.pageData, 'webpageCache'));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/90bac6e57c011e8316afe13568e29da5/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['text'] = 'info@accofg.com';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:info@accofg.com"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/d09f4d28c58125a4012e1443f1367509/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d09f4d28c58125a4012e1443f1367509'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/300c980d76424a13b64788aad768898e/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('300c980d76424a13b64788aad768898e'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('300c980d76424a13b64788aad768898e'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.facebook.com/accofg', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/7d28f5675648b2b8723fa38590687b41/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7d28f5675648b2b8723fa38590687b41'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7d28f5675648b2b8723fa38590687b41'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.linkedin.com/company/anderson-construction-company-of-fort-gaines/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/6ffb65b9c5165a9712bbb3bcbf425dfe/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'1AC64B77-8F6F-4F07-8A6E-12409E539A9C\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/ef44e3b54c91f8b6d036fd417ebb4127/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ef44e3b54c91f8b6d036fd417ebb4127'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ef44e3b54c91f8b6d036fd417ebb4127'))['color'] = 'black';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./components/reusable/r_6219ce08e225695c036f7d7805bab21e/logic/051b08fd32bf3c1ce0df8c5bb31fa28f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (decodeURIComponent((getObjectProperty(((await Backendless.Data.of('admin_Webpages').find(Backendless.DataQueryBuilder.create().setWhereClause('objectId=\'6C183D8A-DE50-4E10-AF62-F0F906CEFACF\'').setPageSize(1)))[0]), 'BlockContent'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/7dbef2fdfb5ce67e529d29023d486af5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await BackendlessUI.Functions.Custom['fn_476e0b7c9ee7ae9ea5da774edbe841c1']()) == true) {
    if ((getObjectProperty(___arguments.context.pageData, 'hideButtonsMode')) == true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarTop')) == true) {
    return ['taskBar-top', 'easeInAndOut'];
  } else {
    return ['taskBar-bottom', 'easeInAndOut'];
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/41a733c49e49c93f1865eeb65dd55c21/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['taskBarTop'] = false;
  ___arguments.context.pageData['taskBarBottom'] = (!(getObjectProperty(___arguments.context.pageData, 'taskBarTop')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarBottom')) != true) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/88719f2b76f7597ab457ed18de1c8fab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['taskBarTop'] = true;
  ___arguments.context.pageData['taskBarBottom'] = (!(getObjectProperty(___arguments.context.pageData, 'taskBarTop')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'taskBarTop')) != true) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/1cf810357e1736ae1334fb2b92dd8cf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	}
  })();
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'Edit mode has been cancelled.', 'warning');
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
    return true;
  }

  return false

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/c2a713898dab8e5bcc146d2f63a1925b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/57fe146ddaa7470303bc7594cc321ac5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	  
  	  var isModified = el[i].getAttribute('data-modified');
  	  console.log(`The value of element ${i} is: ` + isModified);
  	
  	  if (isModified == 'true') {
  	    // console.log('This control is marked as true: ' + el[i])
  	    var dataId = el[i].getAttribute('data-source');
  	    var dataSource = el[i].innerHTML;
  	   await Backendless.Data.of('admin_Webpages').save( ({ 'objectId': dataId ,'BlockContent': (encodeURIComponent(dataSource)) }) );
  	   
  	  } else {
  	    // console.log('This control is NOT marked as true: ' + el[i])
  	    
  	  }
  	
  	}

  })(___arguments.context.pageData));
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, 'Reload', 'The webpage has been updated!','success');
  })();
  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	
  	}
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'editingPage')) == true) {
    return true;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/54e49baf26769a9218ab1aa5e9973a07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	for (let i = 0; i < el.length; i++) {
  	el[i].setAttribute('contenteditable', 'false')
  	el[i].setAttribute('data-modified','false')
  	}
  })();
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	
  	var myFunction = (e)=> {
  	  // console.log(e.currentTarget.getAttribute("data-source"));
  	};
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute("data-source");
  	  // console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = false;
  	  return pageData;
  	};
  	
  	
  	Array.from(document.getElementsByClassName("content_editable")).forEach((editable) => {
  	  // editable.removeEventListener("click", myFunction);
  	  editable.removeEventListener("dblclick", window.___MY_EVENT_LISTENER);
  	});
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'Edit mode has been cancelled.', 'warning');
  })();
  await Backendless.UserService.logout();
  await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      if ((await Backendless.UserService.getCurrentUser(false)) != null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/a063ee21d054b42660c911eda4ac320d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['inEditMode'] = false;
  ___arguments.context.pageData['editingPage'] = false;
  ___arguments.context.pageData['hideButtonsMode'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/f63e128c4b16905e02113eba4f2451b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('pageData.webpageData = ');
  console.log(getObjectProperty(___arguments.context.pageData, 'webpageData'));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/ae7af58eacedaed533e234c7e7127129/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	window.location.reload()
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8391f7d511b6a59d4ae5fddf52e57464/logic/11a22427070ae9ac3452958e85a2888c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

